import {UserRole} from "@prisma/client";
import {useNow, watchDeep} from "@vueuse/core";

export default defineNuxtRouteMiddleware(async (to, from) => {
    const {me} = useAuth()
    const now = useNow({
        interval: 1000
    })

    const handle = async () => {
        const nowValue = toValue(now)
        const nowTimestamp = nowValue.getTime()

        const meValue = toValue(me)
        const role = meValue?.role

        const hasActiveSubscription = role === UserRole.ADMIN || (meValue?.UserSubscribtions ?? []).filter(
            userSubscription =>
                (new Date(userSubscription.startDate)).getTime() &&
                (new Date(userSubscription.startDate)).getTime() <= nowTimestamp &&
                nowTimestamp <= (
                    (new Date(userSubscription.startDate)).getTime() + (
                        userSubscription.Subscribtion.period * 1000 * 60 * 60 * 24
                    )
                )
        ).length > 0

        if (!hasActiveSubscription) {
            await useRouter().replace({
                name: 'buy'
            })
        }
    }

    watchDeep(
        me,
        handle
    )

    await handle()
})
